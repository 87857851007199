import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"

import indexStyles from "../styles/pages/index.module.css"

import tagline from "../images/tagline.png"
import headerVid from "../videos/header-vid.mp4"
import headerPosterImg from "../images/header-poster-img.png"
import model1 from "../images/model1.jpg"
import model2 from "../images/model2.jpg"
import model3 from "../images/model3.jpg"
import contactContent from "../images/contact-content.jpg"

const IndexPage = () => (
    <Layout location="home">
      <SEO title="Home" />
      <div className={ indexStyles.aboutSection }>
        <div className={ indexStyles.aboutContent }>
          <div className={ indexStyles.aboutContentWrapper }>
            <Video src={ headerVid } poster={ headerPosterImg } className={ indexStyles.headerVideo } />
          </div>
        </div>
        <div className={ indexStyles.aboutText }>
          <img src={ tagline } />
          <Link to="/about/">Learn More</Link>
        </div>
      </div>
      <div className={ indexStyles.servicesSection }>
        <div className={ indexStyles.servicesContent }>
          <img src={ model1 } alt=""/>
          <img src={ model2 } alt=""/>
          <img src={ model3 } alt=""/>
        </div>
        <div className={ indexStyles.servicesText }>
          <h3>Your Source For Talent //</h3>
          <p>From models, artists, speakers, and even performers, we make sure that only the best is hired for your events. Boost your business with promising, unique talents only we can provide.</p>
          <Link to="/services/">See More</Link>
        </div>
      </div>
      <div className={ indexStyles.contactSection }>
        <div className={ indexStyles.contactContent }>
          <div className={ indexStyles.borders }></div>
          <img src={ contactContent } alt=""/>
        </div>
        <div className={ indexStyles.contactText }>
          <h3>Work With Us //</h3>
          <p>Add flair to your brand with the talent we have in store. Share your ideas with us to make bring them into reality.</p>
          <Link to="/contact/">Contact Us</Link>
        </div>
      </div>
    </Layout>
)

export default IndexPage