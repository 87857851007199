import React from 'react';

export default ({ src, poster, className }) => {
    return (
        <video
            autoPlay
            muted
            loop
            src={ src }
            poster={ poster }
            className={ className }
        >
            <source src={ src } type="video/mp4" />
            Your device does not support playing 'video/mp4' videos.
        </video>
    )
}